import React, { useContext, useEffect, useRef, useState } from "react";
import localizationStrings from "../../statics/localization/Localization";
import VerticalProgramList from "../verticalProgramList";
import RtukGeneralIcon from "../../assets/icons/svg/RtukGeneralIcon";
import {
  checkNpvrLimit,
  checkNpvrStatus,
  deleteNpvrRecord,
  deleteSerieNpvrRecord,
  startNpvr,
  startSerieNpvr
} from "../../api/npvr/npvr";
import { checkPpvAuth, getCurrentProgramById } from "../../api/ltv/ltv";
import Modal from "../modal/Modal";
import { SessionContext } from "../../context/SessionContext";
import RtukSevenAgeIcon from "../../assets/icons/svg/RtukSevenAgeIcon";
import RtukThirteenAgeIcon from "../../assets/icons/svg/RtukThirteenAgeIcon";
import RtukAgeIcon from "../../assets/icons/svg/RtukAgeIcon";
import ReactDOM from "react-dom";
import Notification from "../notification";
import PpvPayment from "../ppvPayment/PpvPayment";
import moment from "moment";
import { sendCustomNpvrReport } from "../../utils/reporting/customReporting";
import PopupCloseIcon from "../../assets/icons/svg/PopupCloseIcon";
import { fancyHourFormatter } from "../../utils/fancyHourFormatter";
import ShareAction from "../ShareAction/ShareAction";

const ChannelInfo = ({ scrollToTop, setCanPlayPpv, isPltv, setPrevueCode }) => {
  // const [recordStatus, setRecordStatus] = useState(0);

  const [product, setProduct] = useState(null);
  const [showLimitWarning, setShowLimitWarning] = useState(false);
  const [limitMessage, setLimitMessage] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [ppvProduct, setPpvProduct] = useState(null);
  const {
    setMyProgram,
    selectedChannel,
    setPpvProgram,
    recordStatus,
    setRecordStatus,
    isClickedRecord,
    setIsClickedRecord,
    selectedCutvProgram,
    setSelectedCutvProgram,
    selectedCutvProgramTimes,
    userInfo,
    programInfo,
    setProgramInfo
  } = useContext(SessionContext);
  const elRef = useRef(null);
  const isSerieProgram = useRef(false);
  const ppvInterval = useRef(null);
  const canPlayPpv = useRef(null);
  const recordOptionEl = useRef(null);

  const renderRatingIcon = () => {
    return programInfo?.rating?.age === "G" ? (
      <RtukGeneralIcon />
    ) : programInfo?.rating?.age === "7+" ? (
      <RtukSevenAgeIcon />
    ) : programInfo?.rating?.age === "13+" ? (
      <RtukThirteenAgeIcon />
    ) : programInfo?.rating?.age === "18+" ? (
      <RtukAgeIcon />
    ) : null;
  };

  const getCurrentProgram = async (channel) => {
    const data = await getCurrentProgramById(selectedChannel?.id);

    let program = data.data;
    setMyProgram(program);
    //setSelectedCutvProgram(program);
    setProgramInfo(program);
    /* if (
        channel.auth_returncode === "0" &&
        program?.systemrecordenable === "1" &&
        // program?.privaterecordenable === "1" &&
        userInfo.usertype === "1"
      ) {
        getNpvrStatus(program);
      } else {
        setRecordStatus(null);
      } */
  };

  const getNpvrStatus = async (program, isSerie = false) => {
    /*     const { data } = await checkNpvrStatus(
      program?.prevuecode,
      program?.begintime,
      program?.endtime,
      program?.seriesheadid
    );
    if (data.returncode === "0") {
      setRecordStatus(data.status);
    } else if (data.returncode === "10402503") {
      //have no data
      setRecordStatus(null);
    } */
  };

  const checkProgramType = (program, isFromInfo = false) => {
    if (program?.seriesheadid === "0") {
      checkRecordLimit(program);
    } else {
      renderRecordOptions();
    }
  };

  const checkRecordLimit = async (program, isSerieRecord = false) => {
    isSerieProgram.current = isSerieRecord;
    const { data } = await checkNpvrLimit(
      program?.prevuecode,
      program?.seriesheadid
    );
    if (data.returncode === "0") {
      if (data.islimitnpvr === "0") {
        if (isSerieRecord) {
          startSerieRecord(program);
        } else {
          startRecord(program);
        }
      } else if (data.islimitnpvr === "1") {
        setLimitMessage(
          localizationStrings.formatString(
            localizationStrings.npvrHourLimitText,
            { hour: data.limitvalue }
          )
        );
        setShowLimitWarning(true);
      } else if (data.islimitnpvr === "2") {
        setLimitMessage(localizationStrings.npvrEpisodeLimitText);
        setShowLimitWarning(true);
      }
    }
  };

  const startRecord = async (program) => {
    const data = await startNpvr(
      program?.prevuecode,
      selectedChannel.channelcode
    );
    if (data.data.returncode === "0") {
      getNpvrStatus(program);
    }
    sendCustomNpvrReport(
      program?.prevuecode,
      selectedChannel.channelcode,
      program?.seriesheadid,
      1,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const startSerieRecord = async (program) => {
    const data = await startSerieNpvr(
      program?.prevuecode,
      program?.channelcode,
      program?.seriesheadid
    );
    if (data.data.returncode === "0") {
      getNpvrStatus(program, true);
    }
    sendCustomNpvrReport(
      program?.prevuecode,
      selectedChannel.channelcode,
      program?.seriesheadid,
      1,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const deleteRecord = async (code) => {
    const data = await deleteNpvrRecord(code);
    if (data.data.returncode === "0") {
      getNpvrStatus(programInfo);
    } else {
      alert(
        `error code: ${data.data.returncode}, message: ${data.data.errormsg}`
      );
    }
    sendCustomNpvrReport(
      code,
      selectedChannel.channelcode,
      "",
      0,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const deleteSeriesRecord = async (seriesHeadId) => {
    const data = await deleteSerieNpvrRecord(seriesHeadId);
    if (data.data.returncode === "0") {
      getNpvrStatus(programInfo, true);
    } else {
      alert(
        `error code: ${data.data.returncode}, message: ${data.data.errormsg}`
      );
    }
    sendCustomNpvrReport(
      programInfo.prevuecode,
      selectedChannel.channelcode,
      seriesHeadId,
      0,
      data.data.returncode,
      data.data.errormsg
    );
  };

  const getPpvAuth = async (prevueCode) => {
    const { data } = await checkPpvAuth(prevueCode);
    if (data.returncode === "5") {
      let prod = data.productlist.find((item) => item.purchasetype == 3);
      setProduct(prod);
      setPpvProduct(prod);
      if (
        data.otherprevuesauthresult === "0" ||
        data.postbuffer != "0" ||
        data.prebuffer != "0"
      ) {
        if (ppvInterval.current) {
          clearInterval(ppvInterval.current);
        }
        ppvInterval.current = setInterval(() => {
          checkPrebufferAndPostbuffer(data.prebuffer, data.postbuffer);
        }, 60000);
      } else {
        setCanPlayPpv(false);
      }
    } else {
      if (data.returncode === "0") {
        if (!canPlayPpv.current) {
          canPlayPpv.current = true;

          setCanPlayPpv(true, data.productcode);
          setProduct(null);
          setPpvProduct(null);
        }
      } else {
        setProduct(null);
        setPpvProduct(null);
        setCanPlayPpv(false);
      }
    }
  };

  const checkPrebufferAndPostbuffer = (prebuffer, postbuffer) => {
    let now = moment() - parseInt(localStorage.getItem("timeDiff"));

    if (prebuffer != "0") {
      if (now > moment(selectedCutvProgram.endtime)) {
        if (!canPlayPpv.current) {
          canPlayPpv.current = true;
          setCanPlayPpv(true, ppvProduct.productcode);
        }
      } else {
        if (canPlayPpv.current) {
          canPlayPpv.current = false;
          setCanPlayPpv(false);
        }
      }
    }

    if (postbuffer != "0") {
      if (now > moment(selectedCutvProgram.begintime)) {
        if (!canPlayPpv.current) {
          canPlayPpv.current = true;

          setCanPlayPpv(true, ppvProduct.productcode);
        }
      } else {
        if (canPlayPpv.current) {
          canPlayPpv.current = false;
          setCanPlayPpv(false);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedChannel) {
      getCurrentProgram(selectedChannel);
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (!!selectedCutvProgram?.prevuecode) {
      getNpvrStatus(selectedCutvProgram);
      setProgramInfo(selectedCutvProgram);
      scrollToTop();
    }
    return () => {
      // setSelectedCutvProgram(null);
    };
  }, [selectedCutvProgram]);

  useEffect(() => {
    if (isClickedRecord) {
      if (recordStatus !== "999" && recordStatus !== "1") {
        checkProgramType(programInfo);
      } else {
        if (
          programInfo?.seriesheadid === "0" ||
          programInfo?.seriesheadid == ""
        ) {
          deleteRecord(programInfo?.prevuecode);
        } else {
          renderRecordOptions();
        }
      }
    }

    return () => {
      setIsClickedRecord(null);
    };
  }, [isClickedRecord]);

  const renderRecordOptions = () => {
    recordOptionEl.current = document.getElementById("record-option");

    let playerEl = document.getElementById("video-player-true");
    recordOptionEl.current = document.createElement("div");
    recordOptionEl.current.classList.add("record-options-container");
    recordOptionEl.current.setAttribute("id", `record-option`);
    playerEl.appendChild(recordOptionEl.current);

    ReactDOM.render(
      recordStatus === "-2" ? (
        <div className="record-option-wrapper">
          <div className="close-row" style={{ marginTop: "2.4vw" }}>
            <div className="close" onClick={() => unMountRecordOption(false)}>
              <PopupCloseIcon />
            </div>
          </div>
          <div className="record-option-title">
            {localizationStrings.recordOption}
          </div>
          <label
            className="record-option yellow"
            onClick={() => {
              checkRecordLimit(programInfo, true);
              unMountRecordOption();
            }}
          >
            {localizationStrings.recordAllSerie}
          </label>
          <label
            className="record-option transparent"
            onClick={() => {
              // startRecord(programInfo);
              checkRecordLimit(programInfo);
              unMountRecordOption();
            }}
          >
            {localizationStrings.recordThisEpisode}
          </label>
        </div>
      ) : (
        <div className="record-option-wrapper">
          <div className="close-row" style={{ marginTop: "2.4vw" }}>
            <div className="close" onClick={() => unMountRecordOption()}>
              <PopupCloseIcon />
            </div>
          </div>
          <label
            className="record-option yellow"
            onClick={() => {
              deleteSeriesRecord(programInfo?.seriesheadid);
              unMountRecordOption();
            }}
          >
            {localizationStrings.deleteAllSerie}
          </label>
          <label
            className="record-option transparent"
            onClick={() => {
              deleteRecord(programInfo?.prevuecode);
              unMountRecordOption();
            }}
          >
            {localizationStrings.deleteRecordEpisode}
          </label>
        </div>
      ),
      document.getElementById(`record-option`)
    );
  };

  const unMountRecordOption = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById(`record-option`));
    recordOptionEl.current.remove();
  };

  const hoursData = fancyHourFormatter(
    programInfo?.startTime,
    programInfo?.endTime
  );

  const renderProgramInfo = () => {
    if (selectedChannel) {
      return (
        <div>
          <div className="program-title">{programInfo?.title}</div>
          <div className="program-data-row">
            <div className="program-rating">
              <div style={{ color: "white" }}>
                {moment(programInfo?.startTime).format("x") &&
                  moment(parseInt(programInfo?.startTime)).calendar(null, {
                    sameDay: `[${localizationStrings.today}]`,
                    nextDay: `[${localizationStrings.tomorrow}]`,
                    lastDay: `[${localizationStrings.yesterday}]`,
                    nextWeek: "DD MMMM yyyy",
                    lastWeek: "DD MMMM yyyy",
                    sameElse: "DD MMMM yyyy"
                  })}
              </div>
              <span style={{ margin: "0 5px " }}>-</span>
              <div className="date">{hoursData?.startTimeString}</div>
              <span style={{ margin: "0 5px" }}>-</span>
              <div className="date">{hoursData?.endTimeString}</div>
              <div className="program-rating icon">{renderRatingIcon()}</div>
              <div className="program-rating genre" style={{ color: "white" }}>
                {programInfo?.category}
              </div>
              <div className="share-channel">
                <ShareAction
                  programInfo={programInfo}
                  selectedChannel={selectedChannel}
                />
              </div>
            </div>
          </div>
          <div className="program-description">{programInfo?.description}</div>
        </div>
      );
    } else {
      return (
        <div className="no-program-info">{localizationStrings.noProgram}</div>
      );
    }
  };

  return (
    <div id="vert-program-list" ref={elRef}>
      <div className="channel-info-container">{renderProgramInfo()}</div>
      <div>
        <VerticalProgramList selectedCutvProgram={selectedCutvProgram} />
      </div>
      {showLimitWarning ? (
        <Notification
          text={limitMessage}
          confirmText={localizationStrings.continue}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={() => {
            if (isSerieProgram.current) {
              startSerieRecord(programInfo);
            } else {
              startRecord(programInfo);
            }
            setShowLimitWarning(false);
          }}
          onClickNotConfirm={() => {
            setShowLimitWarning(false);
          }}
        />
      ) : null}

      {showPayment ? (
        <PpvPayment
          program={programInfo}
          ppvProduct={ppvProduct}
          closePpv={(val) => {
            setShowPayment(false);
            if (val) {
              getPpvAuth(programInfo.prevuecode);
            }
            // setIsPpvSuccess(val, productcode);
          }}
        />
      ) : null}
    </div>
  );
};

export default ChannelInfo;
