import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import FavoriteIcon from "../../../assets/icons/svg/FavoriteIcon";
import RtukAgeIcon from "../../../assets/icons/svg/RtukAgeIcon";
import RtukGeneralIcon from "../../../assets/icons/svg/RtukGeneralIcon";
import RtukSevenAgeIcon from "../../../assets/icons/svg/RtukSevenAgeIcon";
import RtukThirteenAgeIcon from "../../../assets/icons/svg/RtukThirteenAgeIcon";
import SinglePlayIcon from "../../../assets/icons/svg/SinglePlayIcon";
import InfoIcon from "../../../assets/icons/svg/InfoIcon";
import { SessionContext } from "../../../context/SessionContext";
import localizationStrings from "../../../statics/localization";
import RtukSexualIcon from "../../../assets/icons/svg/RtukSexualIcon";
import RtukViolanceIcon from "../../../assets/icons/svg/RtukViolanceIcon";
import RtukBadBehaviour from "../../../assets/icons/svg/RtukBadBehaviour";
import CheckedIcon from "../../../assets/icons/svg/CheckedIcon";
import {
  getBannerVodDetail,
  getVodDetail,
  toggleFavById
} from "../../../api/vod/vod";
import { doAuth } from "../../../api/user/user";
import { getChannel } from "../../../api/ltv/ltv";
import mainLoadingGif from "../../../assets/loading.gif";
import Notification from "../../notification";
import { prettyTimeFormatter } from "../../../utils/prettyTimeFormatter";
import { findPoster } from "../../../utils/findPoster";

const MainboardItem = ({ item }) => {
  const [vodInfo, setVodInfo] = useState(null);
  const [channelInfo, setChannelInfo] = useState(null);
  const [isFav, setIsFav] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [showParentalControlNotification, setShowParentalControlNotification] =
    useState(false);
  const {
    path,
    setIsChangeFavList,
    setShowLoginMessage,
    userInfo,
    hasLoggedIn
  } = useContext(SessionContext);
  const history = useHistory();
  const timeoutId = useRef(null);

  useEffect(() => {
    let vodStatus = item?.interactions;

    let hasFav =
      vodStatus?.length &&
      vodStatus.find((element) => element?.type === "VOD_WATCHLIST");
    setIsFav(!!hasFav);
  }, [item]);

  const onClickBanner = (isDirectlyPlay = false) => {
    console.log("ıtem", item?.id);

    history.push(
      `${path}/voddetail/${item?.id}${isDirectlyPlay ? "?play=1" : ""}`
    );
    // if (item.linktype === "movie") {
    //   if ((vodInfo?.ratingid & userInfo?.userlevel) > 0) {
    //     setShowParentalControlNotification(true);
    //   } else {
    //     if (vodInfo.programtype === "14" || vodInfo.programtype === "10") {
    //       if (vodInfo.seriestype === "3") {
    //         history.push(
    //           `${path}/voddetail/${vodInfo?.programcode}?ptype=${
    //             item.vodtype
    //           }&recommend=banner${isDirectlyPlay ? "&play=1" : ""}`
    //         );
    //       } else if (vodInfo.seriestype === "2") {
    //         history.push(
    //           `${path}/voddetail/${vodInfo?.seriesprogramcode}?ptype=${item.vodtype}&seasoncode=${item.linksource}&recommend=banner`
    //         );
    //       } else if (vodInfo.seriestype === "1") {
    //         history.push(
    //           `${path}/voddetail/${vodInfo?.seriesprogramcode}?ptype=${item.vodtype}&episodecode=${vodInfo.programcode}&recommend=banner&play=1`
    //         );
    //       }
    //     } else {
    //       history.push(
    //         `${path}/voddetail/${item.linksource}?ptype=${
    //           item.vodtype
    //         }&recommend=banner${isDirectlyPlay ? "&play=1" : ""}`
    //       );
    //     }
    //   }
    // } else if (item.linktype === "tv") {
    //   history.push(`/dashboard/livetv/${channelInfo?.linksource}`);
    // } else if (item.linktype === "url") {
    //   window.open(item?.linksource, "_blank");
    // } else {
    //   alert("unknown link type");
    // }

    timeoutId.current && clearTimeout(timeoutId.current);
    setTimeout(() => {
      timeoutId.current = setShowLoading(false);
    }, 2000);
  };

  const calcDuration = (time) => {
    if (item?.type === "MOVIE") {
      return prettyTimeFormatter(item?.duration);
    } else if (item?.type === "SERIES") {
      return `${item?.children?.length + " " + localizationStrings.season}`;
    }
  };

  const calcFontSize = (text) => {
    let length = text.length;
    // if (length) {
    //   if (length <= 20) {
    //     return 80;
    //   } else if (length <= 30) {
    //     return 60;
    //   } else if (length <= 70) {
    //     return 50;
    //   } else if (length <= 90) {
    //     return 40;
    //   } else if (length <= 110) {
    //     return 30;
    //   }
    // }
    return 40;
  };

  const getMovieInfo = async (id) => {
    const { data } = await getVodDetail(id);
    if (data.returncode === "0") {
      let vodData = data.data.find((item) => item.videotype === "28");
      if (!vodData) vodData = data.data.data[0];
      setIsFav(vodData.isfavourite === "1");
      setVodInfo(vodData);
    }
  };

  const getSeriesInfo = async (id) => {
    const { data } = await getBannerVodDetail(id);
    if (data.returncode === "0") {
      if (data.data.length) {
        let serieData = data.data[0];
        setIsFav(serieData.isfavourite === "1");

        if (serieData.seriestype === "2" || serieData.seriestype === "3") {
          getSeriesAuthInfo(serieData);
        } else if (serieData.seriestype === "1") {
          getSeriesAuthInfo(serieData, serieData.seriesprogramcode);
        }
      }
    }
  };

  const getSeriesAuthInfo = async (item, seriesProgramCode = null) => {
    const { data } = await doAuth(
      seriesProgramCode ? seriesProgramCode : item.programcode,
      null,
      14
    );

    if (data.returncode === "5") {
      if (data.productlist.length) {
        let product = data.productlist.find(
          (item) => item.purchasetype === "3" || item.purchasetype === "7"
        );

        if (!product) {
          item.auth_returncode = "0";
        } else {
          item.auth_returncode = "5";
        }
      }
    } else {
      item.auth_returncode = data.returncode;
    }
    setVodInfo(item);
  };

  const toggleContentOfFavorite = async () => {
    const { data } = await toggleFavById(item?.id);

    setIsChangeFavList(true);
    setIsChangeFavList(false);
  };

  useEffect(() => {
    if (item) {
      if (item.linktype === "movie") {
        if (item.vodtype === "1") {
          getMovieInfo(item.linksource);
        } else if (item.vodtype === "10" || item.vodtype === "14") {
          getSeriesInfo(item.linksource);
        }
      } else if (item.linktype === "tv") {
        setChannelInfo(item);
      }
    }
  }, [item]);

  return (
    <div>
      <div key={item?.id} className="banner-item">
        <img
          className="banner-poster"
          alt={item?.title}
          src={findPoster(item?.posters, "banner")}
        />
        <div
          className="gradient"
          onClick={() => {
            onClickBanner();
          }}
        ></div>
        <div className="transparent"></div>
        <div className="info-wrapper">
          {item ? (
            <div className="info-box">
              <div className="title-wrapper">
                {/* <div
                  className="title"
                  style={{
                    fontSize: `${(calcFontSize(item?.title) - 10) * 0.052}vw`
                  }}
                >
                  {item?.title}
                </div> */}
              </div>
              {/* <div className="metadata-box">
                <div className="metadata-detail">
                  <div className="metadata-row">
                    <p className="description">{item?.summary}</p>
                  </div>
                  <div className="metadata-row">
                    <div className="duration">
                      {vodInfo?.programtype === "1" ||
                      vodInfo?.programtype === "10"
                        ? calcDuration(vodInfo?.elapsedtime)
                        : vodInfo?.seriestype === "3"
                        ? `${vodInfo?.seriesnum} ${localizationStrings.season}`
                        : vodInfo?.seriestype === "2"
                        ? `${vodInfo?.seriesnum} ${localizationStrings.episode}`
                        : null}
                      {calcDuration(item?.duration)}
                    </div>
                    <div className="seperator"></div>
                    <div className="year">{item?.year}</div>
                    <div className="ratings" style={{ marginLeft: "20px" }}>
                      <div className="banner-rating">
                        {item?.maturityRating?.age === "G" ? (
                          <RtukGeneralIcon />
                        ) : item?.maturityRating?.age === "7+" ? (
                          <RtukSevenAgeIcon />
                        ) : item?.maturityRating?.age === "13+" ? (
                          <RtukThirteenAgeIcon />
                        ) : item?.maturityRating?.age === "18+" ? (
                          <RtukAgeIcon />
                        ) : null}

                        {item?.maturityRating?.categories?.includes(
                          "SEXUALITY"
                        ) ? (
                          <div style={{ display: "flex", margin: "0 10px" }}>
                            <RtukSexualIcon />
                          </div>
                        ) : null}
                        {item?.maturityRating?.categories?.includes(
                          "VIOLENCE"
                        ) ? (
                          <div style={{ display: "flex", margin: "0 10px" }}>
                            <RtukViolanceIcon />
                          </div>
                        ) : null}
                        {item?.maturityRating?.categories?.includes(
                          "BAD_BEHAVIOR"
                        ) ? (
                          <div style={{ display: "flex", margin: "0 10px" }}>
                            <RtukBadBehaviour />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="metadata-row">
                    <div className="genre">
                      {vodInfo?.genre?.split(";").join(" - ")}
                    </div>
                  </div>
                </div>
              </div> */}
              <div style={{ display: "flex" }}>
                {!hasLoggedIn || !item?.isUnlocked ? (
                  <div
                    className="play-button"
                    onClick={() => {
                      onClickBanner(true);
                    }}
                  >
                    <span>{localizationStrings.preview}</span>
                  </div>
                ) : (
                  <div
                    className="play-button"
                    onClick={() => {
                      onClickBanner(true);
                      setShowLoading(true);
                    }}
                  >
                    <div className="icon">
                      {showLoading ? (
                        <div className="loading-gif">
                          <img src={mainLoadingGif} />
                        </div>
                      ) : (
                        <SinglePlayIcon />
                      )}
                    </div>
                    <span>{localizationStrings.watchNow}</span>
                  </div>
                )}

                <div
                  className={`button ${isFav ? "selected" : ""}`}
                  onClick={() => {
                    if (hasLoggedIn) {
                      setIsFav((prevState) => !prevState);
                      toggleContentOfFavorite(item?.id);
                    } else {
                      setShowLoginMessage(true);
                    }
                  }}
                >
                  <div className={`icon ${isFav ? "checked" : ""}`}>
                    {isFav ? <CheckedIcon /> : <FavoriteIcon />}
                  </div>
                </div>
                <div
                  className="button"
                  onClick={() => {
                    onClickBanner();
                  }}
                >
                  <div className="icon">
                    <InfoIcon />
                  </div>
                </div>
              </div>
            </div>
          ) : channelInfo ? (
            <div className="info-box">
              <div className="title-wrapper">
                <div className="channel-title" style={{ marginBottom: "20px" }}>
                  {channelInfo?.bannername}
                </div>
              </div>
              <div
                style={{ display: "flex" }}
                onClick={() => {
                  onClickBanner();
                  setShowLoading(true);
                }}
              >
                <div className="play-button">
                  <div className="icon">
                    {showLoading ? (
                      <div className="loading-gif">
                        <img src={mainLoadingGif} />
                      </div>
                    ) : (
                      <SinglePlayIcon />
                    )}
                  </div>
                  <span>{localizationStrings.goChannel}</span>
                </div>
              </div>
            </div>
          ) : item?.linktype === "url" ? (
            <div className="info-box">
              <div className="title-wrapper" style={{ marginBottom: "20px" }}>
                {/* <div
                  className="title"
                  style={{
                    fontSize: `${calcFontSize(item?.bannername) * 0.052}vw`
                  }}
                >
                  {item?.bannername}
                </div> */}
              </div>
              <div style={{ display: "flex" }}>
                <div
                  className="play-button"
                  onClick={() =>
                    window.open(item?.linksource, "_blank").focus()
                  }
                >
                  <span>{localizationStrings.goLink}</span>
                </div>
              </div>
            </div>
          ) : item?.linktype === "column" ? (
            <div className="info-box">
              <div className="title-wrapper" style={{ marginBottom: "20px" }}>
                {/* <div
                  className="title"
                  style={{
                    fontSize: `${calcFontSize(item?.bannername) * 0.052}vw`
                  }}
                >
                  {item?.bannername}
                </div> */}
              </div>
              <div style={{ display: "flex" }}>
                <div
                  className="play-button"
                  style={{ marginRight: "25px" }}
                  onClick={() => {
                    let col1 = item?.linksource.substring(0, 4);
                    let col2 = item?.linksource.substring(0, 6);
                    history.push(
                      `/dashboard/genre/${item?.linksource}/${item?.bannername}?columnType=1&poster=6`
                    );
                  }}
                >
                  <label>{localizationStrings.goCategory}</label>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {showParentalControlNotification ? (
        <Notification
          viewComponent={true}
          confirmText={localizationStrings.parentalControl}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={() => {
            history.push("/dashboard/parentalcontrol");
            setShowParentalControlNotification(false);
          }}
          onClickNotConfirm={() => {
            setShowParentalControlNotification(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default MainboardItem;
