import React, { useEffect, useState } from "react";
import SinglePlayIcon from "../../assets/icons/svg/SinglePlayIcon";
import defaultDetailPoster from "../../assets/images/default-vod-horizontal-poster.png";
import { fancyTimeFormat } from "../../utils/fancyTimeFormat";
import mainLoadingGif from "../../assets/loading.gif";
import { prettyTimeFormatter } from "../../utils/prettyTimeFormatter";

const EpisodeItem = ({
  episode,
  isLast,
  onSelectEpisode,
  bookmark,
  loading
}) => {
  const [progress, setProgress] = useState(null);
  const [progressBar, setProgressBar] = useState(null);
  const progressWidth = (episode.lastDuration / episode.duration) * 100;

  //console.log(episode, episode.lastDuration, episode.duration, isLast, bookmark);

  const calcBookmarkProgress = () => {
    let duration = episode.elapsedtime;
    let arr = duration.split(":");
    let hour = parseInt(arr[0]);
    let min = parseInt(arr[1]);
    let sec = parseInt(arr[2]);
    let totalTime = hour * 3600 + min * 60 + sec;

    let progress = (parseInt(bookmark) / totalTime) * 100;
    setProgress(progress);
  };

  const calcDuration = () => {
    if (episode?.duration) {
      return prettyTimeFormatter(episode?.duration);
    }
  };

  useEffect(() => {
    if (episode.lastDuration === 0 || episode.lastDuration) {
      setProgressBar(true);
    }
  }, []);

  useEffect(() => {
    if (episode) {
      if (bookmark) {
        calcBookmarkProgress();
      }
    }
  }, [episode]);

  let horizontalPoster = episode?.posters.find((poster) => !poster?.vertical);

  return (
    <div
      className="episode-item"
      onClick={() => {
        if (!loading) {
          onSelectEpisode(episode);
        }
      }}
    >
      <div className="episode-box">
        <div className="episode-poster-wrapper">
          <img src={horizontalPoster?.url ?? defaultDetailPoster} />
          <div className="episode-gradient">
            <div className="episode-play-button">
              {loading ? (
                <div className="loading-gif">
                  <img src={mainLoadingGif} />
                </div>
              ) : (
                <SinglePlayIcon />
              )}
            </div>
          </div>
          {bookmark ? (
            <div className="episode-bookmark-progress">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
          ) : null}
          <div className="thumbnail-progress">
            <div className="progress-all" style={{ width: `100%` }}>
              <div
                className={`progress ${
                  progressWidth > 0 ? "progress-animation" : ""
                }`}
                style={{ width: `${progressWidth}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="episode-info-wrapper">
          <div className="title-row">
            <div className="episode-title">{episode?.title}</div>
            <div className="episode-duration">{calcDuration()}</div>
          </div>
          <div className="episode-description-row" title={episode?.summary}>
            {episode?.summary}
          </div>
        </div>
      </div>
      <div
        className="episode-seperator"
        style={{ display: isLast ? "none" : "" }}
      ></div>
    </div>
  );
};

export default EpisodeItem;
