import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import ChannelDetail from "../../components/channelDetail";
import { SessionContext } from "../../context/SessionContext";
import ModalRouting from "../../components/modalRouting/ModalRouting";
import { useHistory, useParams } from "react-router-dom";
import ChannelListWidget from "../../components/channelListWidget/ChannelListWidget";
import localizationStrings from "../../statics/localization";
import { getChannels, toggleLockById } from "../../api/ltv/ltv";
import FilterAllIcon from "../../assets/icons/svg/FilterAllIcon";
import TvGuideIcon from "../../assets/icons/svg/TvGuideIcon";
import SearchCloseIcon from "../../assets/icons/svg/SearchCloseIcon";
import { enableBodyScroll } from "body-scroll-lock";
import SearchIcon from "../../assets/icons/svg/SearchIcon";
import { channelGenres } from "../../api/mockup/mockup";

const LiveTv = () => {
  const [genres, setGenres] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [term, setTerm] = useState("");
  const [itemCount, setItemCount] = useState(null);
  const [allChannelsVar, setAllChannelsVar] = useState(null);

  const {
    selectedChannel,
    setSelectedChannel,
    userFrameConfig,
    setPath,
    isChangeLockChannel,
    setSelectedCutvProgram,
    isChildProfile,
    setIsChangeLockChannel,
    currentProgramTimes,
    setCurrentProgramTimes,
    adminProfile,
    profile,
    selectedGenre,
    setSelectedGenre,
    channelItems,
    setChannelItems
  } = useContext(SessionContext);
  const history = useHistory();
  const intervalId = useRef(null);
  const filterTimeoutId = useRef(null);
  const inputRef = useRef(null);
  const allChannels = useRef([]);

  let { channelNo } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (channelNo && channelItems?.length) {
      const pickedChannel = channelItems.find(
        (channel) => channel?.id == channelNo
      );
      if (!selectedChannel?.canWatch) {
        setSelectedChannel(pickedChannel);
      }
    }
  }, [channelNo, channelItems]);

  const channelSelection = (item, isRefresh = false) => {
    history.push(`/dashboard/livetv/${item?.id}`);
    if (isRefresh) {
      setSelectedChannel((prevState) => {
        let newObj = {};
        Object.assign(newObj, prevState);
        return newObj;
      });
    } else {
      setSelectedChannel(item);
    }
    setPath(`/dashboard/livetv/${item?.id}`);
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  };

  const getChannelItems = async (fromChange) => {
    const data = await getChannels();

    let channels = data.data;

    channels = channels.sort((a, b) => {
      return parseInt(a?.no) - parseInt(b?.no);
    });

    allChannels.current = channels;
    setChannelItems(channels);
    setAllChannelsVar(channels);

    if (!!channelNo && channelNo !== "all" && channelNo !== "first") {
      let item = channels.find((el) => el?.channelcode === channelNo);
      if (item && (item?.channelcode !== selectedChannel?.id || fromChange))
        channelSelection(item);
    } else {
      channelSelection(channels[0]);
    }
  };

  const getGenres = async () => {
    /* const data = await getColumnList(); */
    setGenres(channelGenres);

    setSelectedGenre(channelGenres[0]);
  };

  useLayoutEffect(() => {
    getGenres();
    /* if (
      userFrameConfig?.TV_Column_All &&
      userFrameConfig?.ChildMode_TV_Column_All
    ) {
      if (isChildProfile) {
        let arr = [
          {
            columnname: localizationStrings.child,
            columncode: userFrameConfig?.ChildMode_TV_Column_All
          },
          {
            columncode: "fav-channel",
            columnname: localizationStrings.favChannel
          }
        ];
        setSelectedGenre({
          columnname: localizationStrings.child,
          columncode: userFrameConfig?.ChildMode_TV_Column_All
        });
        setGenres(arr);
      } else {
        getGenres();
      }
    } */
  }, [userFrameConfig]);

  useEffect(() => {
    setPath(`/dashboard/livetv/${channelNo}`);
  }, []);

  useEffect(() => {
    if (isChangeLockChannel) {
      getChannelItems(true);
    }
    /* if (isChangeLockChannel) {
      getChannelItems(true);
    } */
    return () => {
      setIsChangeLockChannel(false);
    };
  }, [isChangeLockChannel]);

  useEffect(() => {
    if (selectedChannel) {
      let obj = {
        begintime: selectedChannel.begintime,
        endtime: selectedChannel.endtime
      };
      setCurrentProgramTimes(obj);
    }
  }, [selectedChannel]);

  useEffect(() => {
    setSelectedCutvProgram(null);
    getChannelItems();
  }, []);

  useEffect(() => {
    let filteredchannelItems = [];

    if (selectedGenre && selectedGenre?.id !== 0 && selectedGenre?.id !== 1) {
      filteredchannelItems = allChannelsVar?.filter(
        (item) => selectedGenre?.name === item.genre
      );
      setChannelItems(filteredchannelItems);
    } else if (selectedGenre && selectedGenre?.id === 1) {
      filteredchannelItems = allChannelsVar?.filter(
        (item) => item.interactions?.includes("CHANNEL_FAV") ?? false
      );
      setChannelItems(filteredchannelItems);
    } else {
      setChannelItems(allChannelsVar);
    }
  }, [selectedGenre]);

  //every 60 secs call channel list because of update current program info
  const getChannelForInterval = async () => {
    const data = await getChannels();

    let channels = data.data;
    /* let item = channels.find(
      (el) => el.channelcode === selectedChannel.channelcode
    );
    if (!!item) {
      if (item.begintime !== currentProgramTimes.begintime) {
        let obj = { begintime: item.begintime, endtime: item.endtime };
        setCurrentProgramTimes(obj);
      }
    }
    channels = channels.sort((a, b) => {
      return parseInt(a?.usermixno) - parseInt(b?.usermixno);
    }); */
    setChannelItems(channels);
  };

  const filterChannels = (input) => {
    if (input.length) {
      let filtered = allChannels.current.filter((item) => {
        if (!item.name || !input) {
          return false;
        }

        const channelNameLowerCase = item.name.toLocaleLowerCase("tr-TR");
        const inputLowerCase = input.toLocaleLowerCase("tr-TR");

        return (
          channelNameLowerCase.startsWith(inputLowerCase) ||
          channelNameLowerCase.includes(inputLowerCase)
        );
      });

      setItemCount(filtered.length);
      setChannelItems(filtered);
    } else {
      setItemCount(null);

      setChannelItems(allChannels.current);
    }
  };

  useEffect(() => {
    if (selectedGenre && currentProgramTimes) {
      if (intervalId.current) clearInterval(intervalId.current);
      let intId = setInterval(() => {
        getChannelForInterval();
      }, 60000);
      intervalId.current = intId;
    }
  }, [selectedGenre, currentProgramTimes]);

  return (
    <div className="container">
      <div
        className="filter-row"
        style={{ marginBottom: isSearchOpen && itemCount ? "15px" : "" }}
      >
        <div className="filter-box">
          <div
            className={`tv-filter ${isFilterOpen ? "selected" : ""}`}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            <div className="label-text">{selectedGenre?.name}</div>
            <div className="filter-icon">
              <FilterAllIcon />
            </div>
          </div>

          <div
            className={`tv-search ${isSearchOpen ? "open" : ""}`}
            onClick={() => {
              if (!isSearchOpen) {
                setIsSearchOpen(true);
                setTimeout(() => {
                  inputRef.current.focus();
                }, 100);
              }
            }}
          >
            <form
              autoComplete="off"
              style={{ width: "100%" }}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <input
                id="tv-search"
                name="tv-search"
                type="text"
                value={term}
                ref={inputRef}
                onChange={(e) => {
                  if (filterTimeoutId.current) {
                    clearTimeout(filterTimeoutId.current);
                  }
                  filterTimeoutId.current = setTimeout(() => {
                    filterChannels(e.target.value);
                  }, 500);
                  setTerm(e.target.value);
                }}
                placeholder={localizationStrings.searchChannel}
              />
              <span
                onClick={() => {
                  if (isSearchOpen) {
                    setTerm("");
                    filterChannels("");
                    setIsSearchOpen(false);
                  }
                }}
              >
                {isSearchOpen ? <SearchCloseIcon /> : <SearchIcon />}
              </span>
            </form>
          </div>
          <div
            className="tv-guide-nav"
            onClick={() => {
              history.push("/dashboard/tvguide");
            }}
          >
            <div className="label-text">{localizationStrings.tvGuide}</div>
            <div className="icon">
              <TvGuideIcon />
            </div>
          </div>
        </div>

        {isSearchOpen && itemCount != null ? (
          <div className="filtered-result-text">
            {localizationStrings.formatString(
              itemCount
                ? localizationStrings.tvSearchResultText
                : localizationStrings.tvSearchNoResultText,
              {
                term: '"' + term + '"',
                count: itemCount
              }
            )}
          </div>
        ) : null}
      </div>

      <div className={`channel-container ${isFilterOpen ? "extend" : ""}`}>
        <div className={`channel-list-column ${isFilterOpen ? "extend" : ""}`}>
          <ChannelListWidget
            key="channellist-1"
            uKey="channellist-1"
            channels={channelItems}
            genres={genres}
            selectedGenre={selectedGenre}
            setSelectedGenre={(val) => {
              setSelectedGenre(val);
              setTerm("");
              setIsSearchOpen(false);
              setItemCount(null);
            }}
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            onSelectChannel={channelSelection}
            isChangeLockChannel={isChangeLockChannel}
            setSelectedCutvProgram={() => {
              /* setSelectedCutvProgram(null); */
            }}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            adminProfile={adminProfile}
            profile={profile}
            userFrameConfig={userFrameConfig}
            setLockChannelCode={(channelId, isLocked) => {
              if (channelId) {
                //debugger;
                setChannelItems((prevState) => {
                  return prevState.map((item) => {
                    if (item.id === channelId) {
                      // Check if 'locked' is true, add 'CHANNEL_LOCK', otherwise remove it
                      if (isLocked) {
                        item.interactions = [
                          ...item.interactions,
                          "CHANNEL_LOCK"
                        ];
                      } else {
                        item.interactions = item.interactions.filter(
                          (interaction) => interaction !== "CHANNEL_LOCK"
                        );
                      }
                    }
                    //setSelectedChannel({ ...item });
                    return item;
                  });
                });
              }
            }}
            setCanWatch={(canWatch) => {
              //debugger;
              if (canWatch) {
                setSelectedChannel((prevState) => {
                  let newObj = {};
                  prevState.canWatch = true;
                  Object.assign(newObj, prevState);
                  return newObj;
                });
                getChannelForInterval();
              }
            }}
          />
        </div>
        <div
          className={`channel-detail-column ${isFilterOpen ? "extend" : ""}`}
        >
          <div className="detail-wrapper">
            <div className="channel-detail-box">
              <ChannelDetail
                selectedChannel={selectedChannel}
                channels={channelItems}
                genres={genres}
                selectedGenre={selectedGenre}
                setSelectedGenre={(val) => {
                  setSelectedGenre(val);
                  setTerm("");
                  setIsFilterOpen(false);
                }}
                searchFilter={(val) => {
                  filterChannels(val);
                  if (val.length) {
                    setTerm(val);
                    setIsSearchOpen(true);
                  } else {
                    setIsSearchOpen(false);
                    setTerm("");
                  }
                }}
                setCanWatch={(canWatch) => {
                  if (canWatch) {
                    console.log("livetv canwatch");
                    setSelectedChannel((prevState) => {
                      let newObj = {};
                      prevState.canWatch = true;
                      Object.assign(newObj, prevState);
                      return newObj;
                    });
                    getChannelForInterval();
                  }
                }}
                setLockChannelCode={(channelId, isLocked) => {
                  setChannelItems((prevState) => {
                    return prevState.map((item) => {
                      if (item.id === channelId) {
                        // Check if 'locked' is true, add 'CHANNEL_LOCK', otherwise remove it
                        if (isLocked) {
                          item.interactions = [
                            ...item.interactions,
                            "CHANNEL_LOCK"
                          ];
                        } else {
                          item.interactions = item.interactions.filter(
                            (interaction) => interaction !== "CHANNEL_LOCK"
                          );
                        }
                      }
                      //setSelectedChannel({ ...item });
                      return item;
                    });
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalRouting />
    </div>
  );
};

export default LiveTv;
