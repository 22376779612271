import React from "react";

const Durations = ({ currentTime, duration }) => {
  return (
    <div className="durations">
      {currentTime ? currentTime : "00:00:00"} /{" "}
      {duration ? duration : "00:00:00"}
    </div>
  );
};

export default Durations;
