import React, { useContext, useEffect, useRef, useState } from "react";
import RtukAgeIcon from "../../assets/icons/svg/RtukAgeIcon";
import RtukGeneralIcon from "../../assets/icons/svg/RtukGeneralIcon";
import RtukSevenAgeIcon from "../../assets/icons/svg/RtukSevenAgeIcon";
import RtukThirteenAgeIcon from "../../assets/icons/svg/RtukThirteenAgeIcon";
import localizationStrings from "../../statics/localization";
import moment from "moment";
import defaultPoster from "../../assets/images/default-vod-horizontal-poster.png";
import SinglePlayIcon from "../../assets/icons/svg/SinglePlayIcon";
import {
  checkNpvrStatus,
  getUserSerieRecords,
  checkNpvrQuota,
  addRecord,
  removeRecord,
  getNpvrWatchUrl
} from "../../api/npvr/npvr";
import { getProgramPlayUrl } from "../../api/ltv/ltv";
import VideoPlayer from "../videoPlayer/VideoPlayer";
import Modal from "../modal/Modal";
import { getPrevueInfo, getChannel, checkPpvAuth } from "../../api/ltv/ltv";
import Notification from "../notification";
import UserRecordItem from "../userRecordItem";
import { getProgramAndNpvrThumbnail } from "../../api/common/commonApi";
import { pageChanged } from "../../utils/reporting/pageReporting";
import PpvPayment from "../ppvPayment/PpvPayment";
import formatProgramThumbnails from "../../utils/formatProgramThumbnails";
import { checkTvodSavetime } from "../../utils/checkTvodSaveTime";
import PopupCloseIcon from "../../assets/icons/svg/PopupCloseIcon";
import ToBeReacordIcon from "../../assets/icons/svg/ToBeReacordIcon";
import useOutsideClick from "../../utils/useOutsideClick";
import CastCarousel from "../castCarousel/CastCarousel";
import { getIsApiErrorPopupOpen, setWhereFrom } from "../../statics/config";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { prettyTimeFormatter } from "../../utils/prettyTimeFormatter";
import ChannelControl from "../videoAlert/ChannelControl";
import getParentalAdvisoryElements from "../../utils/getParentalAdvisory";
import { SessionContext } from "../../context/SessionContext";

const playerOptions = {
  fill: true,
  fluid: true,
  responsive: true,
  preload: "auto",
  controls: true,
  liveui: false,
  autoplay: true,
  controlBar: {
    pictureInPictureToggle: false
  },
  html5: {
    nativeTextTracks: false,
    dash: {
      useTTML: true
    }
  }
};

const ProgramDetail = ({
  programData,
  seriesHeadId,
  epgProgramId,
  closeModal,
  recordItem,
  isRecord,
  fromLive,
  channels,
  fromVodGrid,
  fromTvGuide,
  setSelectedCutvProgram,
  selectedChannel,
  userFrameConfig,
  userInfo,
  location,
  hasHeight,
  isPlayer,
  history,
  directlyPlay,
  isCurrent,
  setRecordStatus,
  myTvChannelProgram,
  canPlay
}) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [program, setProgram] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [channel, setChannel] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [npvrStatus, setNpvrStatus] = useState(null);
  const [deleteepgProgramId, setDeleteableepgProgramId] = useState(null);
  const [showRecordButton, setShowRecordButton] = useState(false);
  const programRef = useRef(null);
  const [posterSrc, setPosterSrc] = useState("");
  const [verticalPosterPath, setVerticalPosterPath] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const [showLimitWarning, setShowLimitWarning] = useState(false);
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const [canWatch, setCanWatch] = useState(true); //about parental settings
  const [ppvProduct, setPpvProduct] = useState(null);
  const [showPayment, setShowPayment] = useState(false);
  const [ppvCanWatch, setPpvCanWatch] = useState(false);
  const [mainProgramData, setMainProgramData] = useState(false);
  const [limitMessage, setLimitMessage] = useState(null);
  const [showDeleteText, setShowDeleteText] = useState(null);
  const [episodeRecord, setEpisodeRecord] = useState(null);
  const [posterHeight, setPosterHeight] = useState(null);

  const { setPlayUrl, playUrl, hasLoggedIn } = useContext(SessionContext);

  const channelRef = useRef(null);
  const isSerieProgram = useRef(false);
  const modalRef = useRef(null);
  const isAnyPopupOpened = useRef(false);
  const posterWrapperRef = useRef(null);
  const deletePopupOpened = useRef(null);

  useOutsideClick(modalRef, () => {
    if (
      !isAnyPopupOpened.current &&
      !getIsApiErrorPopupOpen() &&
      !deletePopupOpened.current
    )
      closeModal();
  });

  const checkWatchable = async () => {
    const data = await getProgramPlayUrl(programData?.epgProgramId);
    if (data.status === 200 && data.data) {
      setMainProgramData(true);
    }
  };

  useEffect(() => {
    isRecord && getNpvrUrl(programData?.epgProgramId);
    programData && getNpvrStatus();
    if (!programData?.watchable && !isRecord) {
      checkWatchable();
    }
  }, []);

  useEffect(() => {
    if (posterWrapperRef.current) {
      setPosterHeight(posterWrapperRef?.current?.offsetHeight);
    }
  }, [posterWrapperRef.current]);

  useEffect(() => {
    isAnyPopupOpened.current =
      showNotification ||
      showOptions ||
      showPayment ||
      showPlayer ||
      showDeleteOptions;
  }, [
    showNotification,
    showOptions,
    showPayment,
    showPlayer,
    showDeleteOptions
  ]);

  const getProgram = async (code, episodeProgram = null) => {
    if (!code) {
      return;
    }
    const data = await getPrevueInfo(code);
    if (data.data.returncode === "0") {
      let programItem = data.data.data[0];

      programRef.current = programItem;
      if (!episodeProgram) {
        setProgram(programItem);
        let posterUrl = `${programItem?.posterpath}${
          programItem?.posterfilelist?.split(";")[2]
        }`;

        let posterUrl2 = `${programItem?.posterpath}${
          programItem?.posterfilelist?.split(";")[0]
        }`;
        if (
          programItem?.ratingid != " " &&
          (programItem?.ratingid & userInfo.userlevel) > 0
        ) {
          setCanWatch(false);
          setDisabled(true);
        } else {
          setCanWatch(true);
          setDisabled(false);
          setPosterSrc(posterUrl);
        }
        setVerticalPosterPath(posterUrl2);
        getChannelDetail(programItem?.channelcode);
      } else {
        setEpisodeRecord(episodeProgram);
        getChannelDetail(programItem?.channelcode, programItem);
      }
    }
  };

  const getUserSeriesNpvr = async (seriesHeadId) => {
    const { data } = await getUserSerieRecords(seriesHeadId);
    if (data.returncode === "0") {
      setEpisodes(data.data);
      if (directlyPlay) {
        // auto play if come with playButton
        getProgram(data?.data[0]?.epgProgramId, data?.data[0]);
      }
    } else {
      directlyPlay = false;
    }
  };

  const getNpvrStatus = async (code, seriesHeadId = null) => {
    const data = await checkNpvrStatus(programData?.epgProgramId);
    if (data.status === 200) {
      setNpvrStatus(data?.data?.status);
      if (isPlayer) {
        setRecordStatus(data.data?.status);
      }
      if (
        data.data.status === "-2" &&
        programRef.current?.privaterecordenable === "1" &&
        programRef.current?.systemrecordenable === "1" &&
        checkTvodSavetime(
          programRef.current.begintime,
          channelRef.current.tvodsavetime
        )
      ) {
        setShowRecordButton(true);
      } else {
        setShowRecordButton(false);
      }
    } else {
      if (isPlayer) {
        setRecordStatus(null);
      }

      setShowRecordButton(false);
    }
  };

  const getChannelDetail = async (code, episodeProgram = null) => {
    const data = await getChannel(code);

    if (data.data.returncode === "0") {
      let channelData = data.data.data[0];
      channelRef.current = channelData;
      if (episodeProgram) {
        getNpvrUrl(episodeProgram, true);
      } else if (directlyPlay) {
        getNpvrUrl(programRef.current);
      } else {
        if (channelData?.auth_returncode === "0") {
          getNpvrStatus(epgProgramId, programRef.current?.seriesheadid);
        }
        if (programRef.current.isppv === "1") {
          let now =
            new Date().getTime() - parseInt(localStorage.getItem("timeDiff"));
          let arr = userFrameConfig.Purchase_PPV_LimitTime.split(",");
          let beforeLimitTime = parseInt(arr[0]) * 60 * 1000;
          let endLimitTime = arr[1] * 60 * 1000;

          if (
            (now >
              moment(
                programRef.current.begintime,
                "YYYY.MM.DD HH:mm"
              ).valueOf() -
                beforeLimitTime &&
              now <
                moment(
                  programRef.current.endtime,
                  "YYYY.MM.DD HH:mm"
                ).valueOf() -
                  endLimitTime) ||
            now >
              moment(programRef.current.endtime, "YYYY.MM.DD HH:mm").valueOf()
          ) {
            getPpvAuth(programRef.current.epgProgramId);
          }
        }
        if (fromLive) {
          selectedChannel?.islocked === "1"
            ? setDisabled(true)
            : setDisabled(false);
        } else {
          if (!isRecord) {
            let isLocked = channels?.find(
              (item) => item?.channelcode === channelData?.channelcode
            )?.islocked;
            isLocked === "1" ? setDisabled(true) : setDisabled(false);
          }
        }
      }

      setChannel(channelData);
    }
  };

  const checkProgramType = (isSerieRecord) => {
    isSerieRecord ? setShowOptions(true) : checkRecordLimit();
  };

  const checkRecordLimit = async (isSerieRecord = false) => {
    isSerieProgram.current = isSerieRecord;
    const data = await checkNpvrQuota();

    if (data.status === 200) {
      startRecord(isSerieRecord);
      if (data.islimitnpvr === "0") {
        startRecord(isSerieRecord);
      } else if (data.islimitnpvr === "1") {
        //too long program
        setLimitMessage(
          localizationStrings.formatString(
            localizationStrings.npvrHourLimitText,
            { hour: data.limitvalue }
          )
        );
        setShowLimitWarning(true);
      } else if (data.islimitnpvr === "2") {
        //too many program
        setLimitMessage(localizationStrings.npvrEpisodeLimitText);
        setShowLimitWarning(true);
      }
    }
  };

  const startRecord = async (isSerieRecord = false) => {
    const currentTime = new Date().getTime();

    let seriesId = isSerieRecord ? programData?.seriesId : null;
    let isRecordedNextEpisodes = isSerieRecord;

    let time = isRecordedNextEpisodes ? programData.startTime : currentTime;

    const data = await addRecord(
      programData?.epgProgramId,
      seriesId,
      time,
      isRecordedNextEpisodes
    );

    if (data.status === 200) {
      getNpvrStatus();
    }
  };

  const deleteRecord = async (isDeletedAll = true) => {
    setShowNotification(false);

    const data = await removeRecord(programData?.epgProgramId, isDeletedAll);

    if (data.status === 200) getNpvrStatus();
  };

  const handleClickWatch = async () => {
    setWhereFrom("");
    if (isRecord) {
      setShowPlayer(true);
      setSelectedCutvProgram(programData);
      return;
    }
    const data = await getProgramPlayUrl(programData?.epgProgramId);
    let programUrl = data.data;
    if (data?.data) {
      if (fromLive) {
        setSelectedCutvProgram(programData);
        setPlayUrl(programUrl);
        window.scrollTo({ top: 0, behavior: "smooth" });
        closeModal();
        return;
      }
      setPlayUrl(programUrl);
      setShowPlayer(true);
      setSelectedCutvProgram(programData);
    }

    //closeModal(true);

    window.scrollTo({ top: 0, behavior: "smooth" });
    /* if (data.data.returncode === "0") {
      setPlayUrl(data.data.httpsplayurl);
      getProgramThumbnail(
        moment(program.utcbegintime, "YYYY.MM.DD HH:mm").format(
          "YYYYMMDDHHmmss"
        ),
        moment(program.utcendtime, "YYYY.MM.DD HH:mm").format(
          "YYYYMMDDHHmmssss"
        )
      );
      setShowPlayer(true);
    } */
  };

  const getNpvrUrl = async (programId, isEpisode = false) => {
    setWhereFrom("");

    const data = await getNpvrWatchUrl(programId);

    if (data.status === 200) {
      let programUrl = data.data;
      if (programUrl) {
        setPlayUrl(programUrl);
      } else {
        setPlayUrl(null);
      }
      //setShowPlayer(true);
      /*  getProgramThumbnail(
        moment(program.utcbegintime, "YYYY.MM.DD HH:mm").format(
          "YYYYMMDDHHmmss"
        ),
        moment(program.utcendtime, "YYYY.MM.DD HH:mm").format("YYYYMMDDHHmmss")
      );
       */
    } else {
      setPlayUrl(null);
    }
  };

  const getProgramThumbnail = async (beginTime, endTime) => {
    const { data } = await getProgramAndNpvrThumbnail(
      userFrameConfig.cmsid,
      channelRef.current.cdnchannelcode,
      beginTime,
      endTime
    );
    if (data.returncode === "0") {
      let val = formatProgramThumbnails(data, beginTime, endTime);
      setThumbnails(val);
    } else {
      setThumbnails([]);
    }
  };

  const getPpvAuth = async (epgProgramId) => {
    const { data } = await checkPpvAuth(epgProgramId);
    if (data.returncode === "5") {
      let prod = data.productlist.find((item) => item.purchasetype == 3);
      setPpvProduct(prod);
    } else {
      if (data.returncode === "0") {
        setPpvCanWatch(true);
      }
      setPpvProduct(null);
    }
  };

  const renderedEpisodesItems = episodes.map((item, index) => {
    return (
      <UserRecordItem
        key={index}
        item={item}
        isEpisode={true}
        clickRecord={(record) => {
          getProgram(record?.epgProgramId, record);
        }}
        setPopupOpened={(val) => (deletePopupOpened.current = val)}
        setDeletedRecordCode={(code) => {
          getUserSeriesNpvr(seriesHeadId);
        }}
      />
    );
  });

  const renderButton = () => {
    if (!hasLoggedIn) {
      return (
        <div>
          <div
            className="program-detail-button"
            style={{
              cursor: "not-allowed"
            }}
          >
            <div className="icon">
              <ToBeReacordIcon />
            </div>
            <label
              style={{
                cursor: "not-allowed"
              }}
            >
              {localizationStrings.recordProgram}
            </label>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex" }}>
          {/* watch available */}
          {(programData?.watchable ||
            mainProgramData ||
            (isRecord && !!playUrl)) &&
            !programData.current && (
              <div onClick={handleClickWatch}>
                <div
                  className="program-detail-button play"
                  style={{ marginRight: "20px" }}
                >
                  <div className="icon">
                    <SinglePlayIcon />
                  </div>
                  <label>{localizationStrings.watch}</label>
                </div>
              </div>
            )}
          {/* record available */}
          {(npvrStatus === "not_found" ||
            npvrStatus === "unknown" ||
            npvrStatus === "not_recorded") && (
            <div
              onClick={() =>
                !disabled && canWatch && checkProgramType(programData?.seriesId)
              }
            >
              <div
                className="program-detail-button"
                style={{
                  cursor: `${disabled || !canWatch ? "not-allowed" : "pointer"}`
                }}
              >
                <div className="icon">
                  <ToBeReacordIcon />
                </div>
                <label
                  style={{
                    cursor: `${
                      disabled || !canWatch ? "not-allowed" : "pointer"
                    }`
                  }}
                >
                  {localizationStrings.recordProgram}
                </label>
              </div>
            </div>
          )}
          {/*  to be record */}
          {npvrStatus === "recording" && (
            <div
              onClick={() => {
                if (!!programData?.seriesId) {
                  setShowDeleteOptions(true);
                } else {
                  setShowNotification(true);
                  isAnyPopupOpened.current = true;
                }
              }}
            >
              <div
                className={`program-detail-button record-button ${
                  !showDeleteText ? "blue" : ""
                }`}
                onMouseEnter={() => setShowDeleteText(true)}
                onMouseLeave={() => setShowDeleteText(false)}
              >
                <div className="icon">
                  <ToBeReacordIcon />
                </div>
                <label>
                  {showDeleteText
                    ? localizationStrings.deleteRecord
                    : localizationStrings.toBeRecord}
                </label>
              </div>
            </div>
          )}
          {/* recorded */}
          {npvrStatus === "recorded" && (
            <div
              onClick={() => {
                if (!!programData?.seriesId) {
                  setShowDeleteOptions(true);
                } else {
                  setShowNotification(true);
                }
              }}
            >
              <div
                className={`program-detail-button record-button ${
                  !showDeleteText ? "yellow" : ""
                }`}
                onMouseEnter={() => setShowDeleteText(true)}
                onMouseLeave={() => setShowDeleteText(false)}
              >
                <div className="icon">
                  <ToBeReacordIcon />
                </div>
                <label>
                  {showDeleteText
                    ? localizationStrings.deleteRecord
                    : localizationStrings.recorded}
                </label>
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  useEffect(() => {
    if (myTvChannelProgram) {
      myTvChannelProgram.prevuename = myTvChannelProgram.programname;
      myTvChannelProgram.begintime = myTvChannelProgram.starttime;
      setProgram(myTvChannelProgram);
      let posterUrl = `${myTvChannelProgram?.posterpath}${
        myTvChannelProgram?.posterfilelist?.split(";")[6]
      }`;

      setPosterSrc(posterUrl);
    } else if (
      isRecord &&
      seriesHeadId !== "0" &&
      !!seriesHeadId &&
      userFrameConfig
    ) {
      setProgram(recordItem);
      let posterUrl = `${recordItem?.posterpath}${
        recordItem?.posterfilelist?.split(";")[2]
      }`;
      let posterUrl2 = `${recordItem?.posterpath}${
        recordItem?.posterfilelist?.split(";")[0]
      }`;
      setPosterSrc(posterUrl);
      setVerticalPosterPath(posterUrl2);
      getUserSeriesNpvr(seriesHeadId);
    } else if (!!epgProgramId && userInfo) {
      getProgram(epgProgramId);
    }
  }, [
    epgProgramId,
    seriesHeadId,
    userInfo,
    userFrameConfig,
    myTvChannelProgram
  ]);

  useEffect(() => {
    if (programRef.current) {
      let obj = {
        epgProgramId: programRef.current.epgProgramId,
        seriesheadid: programRef.current.seriesheadid,
        channelcode: programRef.current.channelcode
      };
      pageChanged(
        isRecord ? "/npvrdetail" : "/programdetail",
        location.pathname,
        "",
        obj
      );
    }
  }, [programRef.current]);

  useEffect(() => {
    disableBodyScroll(document.body, { reserveScrollBarGap: true });
    return () => {
      enableBodyScroll(document.body);
      let obj = {
        epgProgramId: programRef.current?.epgProgramId,
        seriesheadid: programRef.current?.seriesheadid,
        channelcode: programRef.current?.channelcode
      };
      pageChanged(
        location.pathname,
        isRecord ? "/npvrdetail" : "/programdetail",
        "",
        obj
      );
    };
  }, []);

  useEffect(() => {
    if (directlyPlay) {
      if (seriesHeadId) {
        getUserSeriesNpvr(seriesHeadId);
      }
    }
  }, []);
  // useEffect(()=>{
  //   if(episodes != []){
  //     if (directlyPlay) {
  //       if (isRecord) {
  //         console.log("episodes",episodes[0]?.epgProgramId);
  //         //getProgram(episodes[0]?.epgProgramId,episodes[0]);
  //       }
  //     }
  //   }
  // },[episodes])

  let mainHorizontalPoster =
    programData.posters && programData.posters?.length > 0
      ? programData?.posters.find((poster) =>
          poster?.type ? poster?.type : poster?.posterType === "HorizontalHigh"
        )
      : {};

  return (
    <Modal viewClass={`vod-modal `} isHide={directlyPlay}>
      <div
        ref={modalRef}
        className="vod-detail-container"
        style={{ opacity: directlyPlay ? 0 : 1 }}
      >
        <div
          className="vod-detail-box"
          style={hasHeight ? { height: "calc(100vh - 140px)" } : null}
        >
          <div>
            <div ref={posterWrapperRef} className="poster-wrapper">
              <div className="img-wrapper">
                {canWatch ? (
                  <img
                    alt="vod detail poster"
                    src={
                      mainHorizontalPoster?.url
                        ? mainHorizontalPoster?.url
                        : mainHorizontalPoster?.posterUrl ?? defaultPoster
                    }
                    onError={() => setPosterSrc(defaultPoster)}
                  />
                ) : (
                  <ChannelControl />
                )}
              </div>
              <div className="close" onClick={() => closeModal()}>
                <PopupCloseIcon />
              </div>

              <div
                className="container-absolute"
                style={{
                  top: `${posterHeight * 0.5}px`
                }}
              >
                <div className="container-1">
                  <div className="title">{programData?.title}</div>

                  <div className="record-button" style={{ display: "flex" }}>
                    <div className="button-wrapper">{renderButton()}</div>
                  </div>
                </div>
                {isRecord && seriesHeadId && seriesHeadId !== "0" ? (
                  <div className="record-items-wrapper">
                    {renderedEpisodesItems}
                  </div>
                ) : (
                  <div className="container-2">
                    <div className="row">
                      <div className="genre">
                        {programData?.category
                          ?.split(";")
                          ?.map((item, index) => {
                            return (
                              <a
                                key={index}
                                className="link"
                                onClick={() =>
                                  history.push(`/dashboard/search/${item}`)
                                }
                              >
                                {item}
                                {`${
                                  programData?.category?.split(";").length -
                                    1 ===
                                  index
                                    ? ""
                                    : ", "
                                }`}
                              </a>
                            );
                          })}
                      </div>
                      <div className="date">
                        {prettyTimeFormatter(programData?.duration)}
                      </div>
                      <div className="ratings">
                        <div className="rating">
                          {programData?.rating?.age === "G" ? (
                            <RtukGeneralIcon />
                          ) : programData?.rating?.age === "7+" ? (
                            <RtukSevenAgeIcon />
                          ) : programData?.rating?.age === "13+" ? (
                            <RtukThirteenAgeIcon />
                          ) : programData?.rating?.age === "18+" ? (
                            <RtukAgeIcon />
                          ) : null}
                        </div>
                        {getParentalAdvisoryElements(
                          program?.rating?.categories
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="synopsis-box">
                        {programData?.description}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="container-3"
                  style={{
                    display: !programData?.casts?.length > 0 ? "none" : ""
                  }}
                >
                  <div className="card">
                    <div className="title">{localizationStrings.cast}</div>
                    <CastCarousel
                      casts={programData?.casts}
                      history={history}
                    />
                  </div>
                </div>
              </div>

              <div className="gradient"></div>
            </div>
          </div>
        </div>
      </div>
      {showPlayer ? (
        <Modal viewClass={`player-modal `}>
          <VideoPlayer
            key="program-player"
            options={playerOptions}
            src={playUrl}
            vod={programData}
            epgProgramId={epgProgramId}
            isRecord={isRecord}
            closeRecordModal={() => {
              setShowPlayer(false);
              if (directlyPlay) {
                closeModal(directlyPlay);
              }
            }}
            thumbnails={thumbnails}
            isTimeshiftEnable={true}
            fromVodGrid={fromVodGrid}
            fromTvGuide={fromTvGuide}
          />
        </Modal>
      ) : null}

      {showNotification ? (
        <Notification
          text={localizationStrings.deleteRecordConfirmText}
          confirmText={localizationStrings.delete}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={() => {
            deleteRecord(true);
          }}
          onClickNotConfirm={() => setShowNotification(false)}
          dontUsePortal={isPlayer}
        />
      ) : null}
      {showOptions ? (
        <Modal viewClass="record-option-modal">
          <div className="record-option-wrapper">
            <div className="close-row" style={{ marginTop: "2.4vw" }}>
              <div className="close" onClick={() => setShowOptions(false)}>
                <PopupCloseIcon />
              </div>
            </div>
            <div className="record-option-title">
              {localizationStrings.recordOption}
            </div>
            <label
              className="record-option yellow"
              onClick={() => {
                checkRecordLimit(true);
                setShowOptions(false);
              }}
            >
              {localizationStrings.recordAllSerie}
            </label>
            <label
              className="record-option transparent"
              onClick={() => {
                checkRecordLimit();
                setShowOptions(false);
              }}
            >
              {localizationStrings.recordThisEpisode}
            </label>
          </div>
        </Modal>
      ) : null}
      {showDeleteOptions ? (
        <Modal viewClass="record-option-modal">
          <div className="record-option-wrapper">
            <div className="close-row" style={{ marginTop: "2.4vw" }}>
              <div
                className="close"
                onClick={() => setShowDeleteOptions(false)}
              >
                <PopupCloseIcon />
              </div>
            </div>
            <div className="record-option-title">
              {localizationStrings.deleteRecordOption}
            </div>
            <label
              className="record-option yellow"
              onClick={() => {
                deleteRecord(true);
                setShowDeleteOptions(false);
              }}
            >
              {localizationStrings.deleteAllSerie}
            </label>
            <label
              className="record-option transparent"
              onClick={() => {
                deleteRecord(false);
                setShowDeleteOptions(false);
              }}
            >
              {localizationStrings.deleteRecordEpisode}
            </label>
          </div>
        </Modal>
      ) : null}
      {showLimitWarning ? (
        <Notification
          text={limitMessage}
          confirmText={localizationStrings.continue}
          notConfirmText={localizationStrings.cancel}
          onClickConfirm={() => {
            startRecord(isSerieProgram.current);

            setShowLimitWarning(false);
          }}
          onClickNotConfirm={() => {
            setShowLimitWarning(false);
          }}
          dontUsePortal={isPlayer}
        />
      ) : null}
      {showPayment ? (
        <PpvPayment
          program={programRef.current}
          ppvProduct={ppvProduct}
          closePpv={(val) => {
            setShowPayment(false);
            if (val) {
              getPpvAuth(programRef.current.epgProgramId);
            }
            // setIsPpvSuccess(val, productcode);
          }}
        />
      ) : null}
    </Modal>
  );
};

export default ProgramDetail;
