import React, { useEffect, useState } from "react";
import Namespace from "../../assets/icons/svg/Namespace";
import RtukAgeIcon from "../../assets/icons/svg/RtukAgeIcon";
import RtukGeneralIcon from "../../assets/icons/svg/RtukGeneralIcon";
import RtukSevenAgeIcon from "../../assets/icons/svg/RtukSevenAgeIcon";
import RtukThirteenAgeIcon from "../../assets/icons/svg/RtukThirteenAgeIcon";
import DisplayDate from "../displayDate";
import moment from "moment";
import VisibilitySensor from "react-visibility-sensor";
import defaultHorizontalPoster from "../../assets/images/default-vod-horizontal-poster.png";
import LiveIcon from "../../assets/icons/svg/LiveIcon";
import localizationStrings from "../../statics/localization";
import ImageView from "../imageView";
import defaultPoster from "../../assets/images/no-portrait-poster.png";
import { getProgramsById } from "../../api/ltv/ltv";

const TvGuideRow = ({ channelid, date, onClickProgram }) => {
  const [posterUrl, setPosterUrl] = useState("");
  const [programs, setPrograms] = useState([]);

  const getProgramsByChannel = async (formatedDate) => {
    try {
      const data = await getProgramsById(channelid, formatedDate);
      setPrograms(data?.data);
      //setAllPrograms(data.data);
    } catch (err) {
      //setAllPrograms([]);
    }
  };

  useEffect(() => {
    if (!!date) {
      let formatedDate = moment().add(date.id, "days").format("DD.MM.yyyy");
      getProgramsByChannel(formatedDate);
    }
  }, [date]);

  const renderedItems = programs.map((item, index) => {
    let beginTime = moment(
      item?.startTime
      //item.begintime ? item.begintime : item.starttime
    ).utc();
    let endTime = moment(item.endTime).utc();
    let cnt = 0;
    let now = moment().utc();
    let isCurrent = beginTime <= now && now < endTime;
    let progress = null;

    if (isCurrent) {
      progress = ((now - beginTime) / (endTime - beginTime)) * 100;
    }

    return (
      <VisibilitySensor>
        {({ isVisible }) => {
          isVisible && ++cnt;

          return (
            <div
              key={index + item.epgProgramId}
              className="multi-program-item-box"
            >
              <div className="program-item-inner">
                <div className="program-info">
                  <div className="inner-info">
                    <div
                      className="title"
                      onClick={() => onClickProgram(item, isCurrent)}
                      title={item.title}
                    >
                      {item.title}
                    </div>
                    <div className="genre-date">
                      {item.category} -{" "}
                      {moment.unix(item?.startTime / 1000).format("HH:mm")}
                      <span style={{ margin: "0 5px" }}>→</span>{" "}
                      {moment.unix(item?.endTime / 1000).format("HH:mm")}
                    </div>
                  </div>
                  <div className="inner-rating">
                    <div
                      className="info-button"
                      onClick={() => onClickProgram(item, isCurrent)}
                    >
                      {<Namespace />}
                    </div>
                    {item?.rating.age === "G" ? (
                      <RtukGeneralIcon />
                    ) : item?.rating.age === "+7" ? (
                      <RtukSevenAgeIcon />
                    ) : item?.rating.age === "+13" ? (
                      <RtukThirteenAgeIcon />
                    ) : item?.rating.age === "+18" ? (
                      <RtukAgeIcon />
                    ) : null}
                  </div>
                </div>
                <div className="program-poster">
                  <ImageView
                    src={`${
                      item?.posters?.length ? item?.posters[0]?.url : ""
                    }`}
                    defaultPoster={defaultHorizontalPoster}
                  />
                  <div
                    className="live-program-indicator"
                    style={{ display: !isCurrent ? "none" : "" }}
                  >
                    <span className="icon">
                      <LiveIcon />
                    </span>
                    <span className="text">{localizationStrings.live}</span>
                  </div>
                </div>
              </div>
              <div
                className="current-progress"
                style={{ display: !isCurrent ? "none" : "" }}
              >
                <div
                  className="progress"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          );
        }}
      </VisibilitySensor>
    );
  });

  return <div className="tvguide-row">{renderedItems}</div>;
};

export default TvGuideRow;
