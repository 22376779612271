import React, { useContext, useEffect, useRef, useState } from "react";
import localizationStrings from "../../statics/localization";
import {
  getRecordedList,
  getWillBeRecordedList,
  checkNpvrQuota,
  getSeriesDetail
} from "../../api/npvr/npvr";
import { useHistory, useLocation } from "react-router-dom";
import ReturnIcon from "../../assets/icons/svg/ReturnIcon";
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import UserRecordItem from "../userRecordItem/UserRecordItem";
import { SessionContext } from "../../context/SessionContext";
import ModalPortal from "../modalPortal";
import ProgramDetail from "../programDetail";
import { getIsFromPip, setIsFromPip } from "../../statics/config";

const UserRecords = (setDeletedRecordCode) => {
  const [records, setRecords] = useState(null);
  const [npvrQuota, setNpvrQuota] = useState("");
  const [npvrRemaining, setNpvrRemaining] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(1);
  const [showSeriesEpisode, setShowSeriesEpisode] = useState(false);
  const [selectedSerieRecord, setSelectedSerieRecord] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const { userFrameConfig, userInfo, setSelectedCutvProgram } =
    useContext(SessionContext);
  const allRecords = useRef(null);
  const history = useHistory();
  const location = useLocation();

  let isFromPip = getIsFromPip();

  useEffect(() => {
    getRecords();
  }, [selectedFilter]);

  const getRecords = async () => {
    if (selectedFilter === 1) {
      const data = await getRecordedList();
      if (data.status === 200) {
        allRecords.current = data.data;
      }
    } else if (selectedFilter === 2) {
      const data = await getWillBeRecordedList();
      if (data.status === 200) {
        allRecords.current = data.data;
      }
    }
    setRecords(allRecords.current);
  };

  const getSeriesRecordings = async (record) => {
    const data = await getSeriesDetail(record.epgProgramId, record.seriesId);
    if (data.status === 200) {
      console.log({ data });
      setRecords(data.data);
    }
  };

  const renderedItems = records?.map((item) => {
    return (
      <UserRecordItem
        item={item}
        setDeletedRecordCode={(code) => {
          getRecords();
          getNpvrQuota();
        }}
        clickRecord={(record) => {
          if (
            !!record?.seriesId &&
            selectedFilter === 1 &&
            !showSeriesEpisode
          ) {
            getSeriesRecordings(record);
            setShowSeriesEpisode(true);
            setSelectedSerieRecord(record);
          } else if (selectedFilter === 2) {
            return;
          } else {
            setSelectedRecord(record);
            setShowModal(true);
          }
        }}
        isEpisode={selectedFilter === 1 ? showSeriesEpisode : true}
        selectedFilter={selectedFilter}
        setPopupOpened={() => {}}
      />
    );
  });

  const getNpvrQuota = async () => {
    const data = await checkNpvrQuota();
    if (data.status === 200) {
      const { quotaLeft, maxNpvrQuota } = data.data;

      setNpvrQuota(maxNpvrQuota - quotaLeft);
      setNpvrRemaining(quotaLeft);
      setPercentage(((maxNpvrQuota - quotaLeft) / maxNpvrQuota) * 100);
    }
  };

  //life cycle methods
  useEffect(() => {
    getRecords();
    getNpvrQuota();
  }, []);

  useEffect(() => {
    if (isFromPip) {
      setSelectedRecord(isFromPip);
      setShowModal(true);
      setIsFromPip(null);
    }
  }, [isFromPip]);

  return (
    <div className="settings-container">
      <div
        className="settings-icon"
        onClick={() => {
          if (showSeriesEpisode) {
            setShowSeriesEpisode(false);
            getRecords();
          } else {
            history.push(`/dashboard/settings`);
          }
        }}
      >
        <ReturnIcon />
        <label>{localizationStrings.myRecords}</label>
      </div>
      <div>
        <div style={{ padding: "0 5.2vw" }}>
          {showSeriesEpisode ? (
            <div className="record-series">
              <div className="info-box">
                <div
                  className="row"
                  style={{ marginTop: "0.5vw", marginBottom: ".26vw" }}
                >
                  <label className="title">{selectedSerieRecord?.title}</label>
                </div>
                <div className="row">
                  <div className="info">
                    <label style={{ color: "#FFFFFF", fontSize: ".78vw" }}>
                      {selectedSerieRecord?.category}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div
            className="edit-record-wrapper"
            style={{ display: showSeriesEpisode ? "none" : "" }}
          >
            <div className="record">
              <div className="serie-record">
                <label>
                  {localizationStrings.npvrQuotaa}{" "}
                  <span>{npvrQuota + " " + localizationStrings.minutes}</span>
                </label>
                <div className="quota">
                  {localizationStrings.formatString(localizationStrings.total, {
                    percentage: parseInt(percentage)
                  })}
                </div>
              </div>
              <div className="icon">
                <CircularProgressbarWithChildren
                  value={percentage}
                  styles={buildStyles({
                    pathTransitionDuration: 0.5,
                    pathColor: `#D5DC36`,
                    textColor: "#fff",
                    trailColor: "#707070"
                  })}
                >
                  <div className="circular-progress">
                    <span>%</span>
                    <label>{parseInt(percentage)}</label>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <div className="record-serie">
                <label>
                  {localizationStrings.npvrRemaining}{" "}
                  <span>
                    {npvrRemaining + " " + localizationStrings.minutes}
                  </span>
                </label>
                <div className="expired">{localizationStrings.expired}</div>
              </div>
            </div>
          </div>

          <div
            className="filter-option "
            style={{ display: showSeriesEpisode ? "none" : "" }}
          >
            <label
              className={`yellow ${selectedFilter === 1 ? "selected" : ""}`}
              onClick={() => setSelectedFilter(1)}
            >
              {localizationStrings.recordSaved}
            </label>

            <label
              className={`blue ${selectedFilter === 2 ? "selected" : ""}`}
              onClick={() => setSelectedFilter(2)}
            >
              {localizationStrings.toBeRecorded}
            </label>
          </div>
          {selectedFilter === 1 ? (
            <div className="record-items-wrapper">
              {renderedItems}
              {records !== null && !records?.length ? (
                <div className="not-found">{localizationStrings.noRecords}</div>
              ) : null}
            </div>
          ) : null}
          {selectedFilter === 2 ? (
            <div className="record-items-wrapper">
              {renderedItems}
              {records !== null && !records?.length ? (
                <div className="not-found">{localizationStrings.noRecords}</div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>

      {showModal ? (
        <ModalPortal viewClass="vod-modal">
          {/* <ProgramDetail
            epgProgramId={selectedRecord?.epgProgramId}
            seriesHeadId={selectedRecord?.seriesheadid}
            closeModal={(val) => {
              setShowModal(false);
            }}
            isRecord={true}
            recordItem={selectedRecord}
            userFrameConfig={userFrameConfig}
            userInfo={userInfo}
            location={location}
            history={history}
            directlyPlay={true}
          /> */}
          <ProgramDetail
            prevueCode={selectedRecord?.epgProgramId}
            closeModal={() => setShowModal(false)}
            fromLive={true}
            setSelectedCutvProgram={setSelectedCutvProgram}
            userFrameConfig={userFrameConfig}
            userInfo={userInfo}
            location={location}
            history={history}
            programData={selectedRecord}
            isRecord={true}
          />
        </ModalPortal>
      ) : null}
    </div>
  );
};

export default UserRecords;
