import React from "react";

const CopyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="6.5" y="6.5" width="9" height="13" rx="1.5" stroke="#000000" />
      <path
        d="M8.5 6C8.5 5.17157 9.17157 4.5 10 4.5H16C16.8284 4.5 17.5 5.17157 17.5 6V16C17.5 16.8284 16.8284 17.5 16 17.5"
        stroke="#000000"
      />
    </svg>
  );
};

export default CopyIcon;
