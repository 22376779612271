import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { useParams, useRouteMatch } from "react-router";

import ContentGrid from "../../components/contentGrid";
import useQuery from "../../utils/useQuery";
import carouselImg from "../../assets/icons/svg/Icon awesome-angle-right.svg";
import { getVodsByLabel } from "../../api/vod/vod";
import { getRecordedList, getWillBeRecordedList } from "../../api/npvr/npvr";

const GridView = ({ dontUseGrid }) => {
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const { columnCode, columnName } = useParams();

  const history = useHistory();

  const pageRef = useRef(0);
  const itemsRef = useRef([]);
  const totalCountRef = useRef(0);

  let query = useQuery();
  let posterPosition = query.get("poster");

  let pageItemCount = 20; // request for other pages vod count

  useEffect(() => {
    if (columnCode === "record") return getRecords();
    getItems();
  }, []);

  const getItems = async () => {
    const { data } = await getVodsByLabel(
      columnCode,
      pageRef.current,
      pageItemCount
    );

    pageRef.current++;

    let listItems = null;

    if (data.channels?.length > 0) {
      listItems = data.channels;
    } else if (data.contents?.length > 0) {
      listItems = data.contents;
    }

    if (listItems) {
      itemsRef.current = itemsRef.current.concat(listItems);
      totalCountRef.current = data?.totalContents;
      setItems(itemsRef.current);
    } else {
      setHasMore(false);
    }
  };

  const getRecords = async () => {
    let records = [];
    const recordedDatatemp = await getRecordedList();
    if (recordedDatatemp.status === 200) {
      records = recordedDatatemp.data;
    }
    const willBeRecordedDatatemp = await getWillBeRecordedList();
    if (
      willBeRecordedDatatemp.status === 200 &&
      willBeRecordedDatatemp.data.length > 0
    ) {
      records = [...records, ...willBeRecordedDatatemp.data];
    }
    if (records.length > 0) {
      totalCountRef.current = records.length;
      setItems(records);
    }
  };

  //bu view komponent gridi yönetecek. Gridin datası burada alınacak ve contentGrid komponentine gönderilecek.
  return (
    <div style={{ marginTop: "100px", padding: "0 40px" }}>
      <div
        className="label-title"
        style={{ marginLeft: "20px" }}
        onClick={() => {
          history.goBack();
        }}
      >
        <img className="go-back-icon" src={carouselImg} />
        {columnName}
      </div>

      <InfiniteScroll
        dataLength={items?.length}
        next={() => {
          if (totalCountRef.current > items?.length) {
            getItems();
          }
        }}
        hasMore={hasMore}
      >
        <ContentGrid
          items={items}
          posterPosition={posterPosition}
          columnCode={columnCode}
        />
      </InfiniteScroll>
    </div>
  );
};

export default GridView;
