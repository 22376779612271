import React, { useEffect, useState } from "react";
import localizationStrings from "../../../statics/localization";
import { getVodPlayUrlById } from "../../../api/vod/vod";

const NextEpisode = ({
  playNextEpisode,
  nextEpisode,
  selectedSeason,
  vod,
  hideNextEpisode,
  player,
  isCutvVod,
  nextSeason,
  playerRef,
  selectedEpisode
}) => {
  // const isClickedContinueRef = useRef(false);
  // useEffect(() => {
  //   if (!!player && !!nextEpisode) {
  //     player.off("ended");
  //     player.on("ended", () => {
  //       console.log("VIDEO ENDED NEXT EPISDOE:");
  //       isClickedContinueRef.current
  //         ? goBack(player.currentTime())
  //         : playNextEpisode();
  //     });
  //   }
  // }, [player]);
  const goNextEpisode = (brPoint = 0) => {
    let bookmarkTime;
    let currentTime;
    let duration;
    if (brPoint) {
      bookmarkTime = parseInt(brPoint);
    } else {
      currentTime = parseInt(playerRef.current?.currentTime());
      bookmarkTime = currentTime;
    }
    duration = playerRef.current.duration();
    if (currentTime <= duration - 1 && duration && currentTime) {
      const vodId = vod?.type === "SERIES" ? selectedEpisode?.id : vod?.id;

      if (vodId) {
        const bookmarkControl = async () => {
          const { event } = await getVodPlayUrlById(vodId, currentTime);
        };
        bookmarkControl();
      }
    }
  };
  return (
    <div className="next-episode-wrapper">
      <div className="next-episode-box">
        <div
          className="yellow-button"
          style={{ width: "200px", marginBottom: "0" }}
          onClick={() => {
            hideNextEpisode();
          }}
        >
          {localizationStrings.continue}
        </div>
        <div
          className="continue-button"
          style={{ marginBottom: "11px" }}
          onClick={() => {
            player.off("ended");
            playNextEpisode();
            goNextEpisode();
          }}
        >
          {localizationStrings.next}
        </div>
        <div className="episode-info-box">
          <label className="episode-title">{vod?.programname}</label>
          {isCutvVod ? (
            <span className="episode-info">"{nextEpisode?.programname}"</span>
          ) : (
            <span className="episode-info">
              {!nextSeason ? selectedSeason?.seasonNo : nextSeason?.seasonNo}. S
              - {!nextSeason ? nextEpisode?.episodeNo : "1"}.{" "}
              {localizationStrings.getLanguage() === "eng" ? "E" : "B"}
              {/* "{nextEpisode?.title}" */}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default NextEpisode;
